.leftArrow , .rightArrow{
    height:30px;
    width: 30px;
}
.leftArrow {
    border-top: 10px solid white;
    border-left: 10px solid white;
    
}

.rightArrow{
    border-bottom: 10px solid white;
    border-right: 10px solid white;
    display: flex;
    margin-left: auto;
    margin-top:40px;
    
}

